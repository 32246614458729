import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import ChevronTopIcon from '../../assets/icons/chevron-up.svg?react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
/*
  How to use it:
  <Collapse activeSection="step1">
    <CollapseSection
      id="step1"
      title="step 1"
      icon={<HomeIcon className="h-5 w-5 stroke-danger-900" />}>
      <div className="p-4 text-sm font-light text-gray-700">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi non quis exercitationem
        culpa nesciunt nihil aut nostrum explicabo reprehenderit optio amet ab temporibus
        asperiores quasi cupiditate. Voluptatum ducimus voluptates voluptas?
      </div>
    </CollapseSection>
    <CollapseSection id="step2" title="step 2">
      test2
    </CollapseSection>
    <CollapseSection id="step3" title="step 3">
      test2
    </CollapseSection>
  </Collapse>
*/

export interface CollapseProps {
  activeSection?: string;
  urlMode?: boolean;
  reduxMode?: boolean;
  children: ReactElement<CollapseSectionProps>[];
  onSectionChange?: (value?: string) => void;
}

const Collapse = ({ children, urlMode = false, reduxMode = false, ...props }: CollapseProps) => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(props.activeSection);

  useEffect(() => {
    setActiveSection(props.activeSection);
    setTimeout(() => {
      const section = document.querySelector(`#${props.activeSection} button`);
      if (section) {
        section.scrollIntoView({
          behavior: 'smooth'
        });
      }
    });
  }, [props.activeSection]);

  const handleClick = (newActiveSection: string, active: boolean = true) => {
    if (active) {
      if (urlMode) {
        navigate(`/ingestion/new/${newActiveSection}`);
      } else {
        if (activeSection === newActiveSection) {
          if (!reduxMode) setActiveSection(undefined);
          if (props.onSectionChange) props.onSectionChange(undefined);
        } else {
          if (!reduxMode) setActiveSection(newActiveSection);
          if (props.onSectionChange) props.onSectionChange(newActiveSection);
        }
      }
    }
  };

  return (
    <div className="w-full bg-white border-t border-gray-300">
      <div className="flex flex-col text-left divide-y divide-gray-300">
        {children &&
          children.map((child: ReactElement<CollapseSectionProps>, index: number) => (
            <div key={index} id={child.props.id} className={`w-full`}>
              <button
                type="button"
                className={clsx(
                  'p-4 text-left font-medium text-lg flex flex-row items-center gap-2 w-full',
                  {
                    'bg-gray-200 border-b border-gray-300': activeSection === child.props.id,
                    'bg-gray-50': activeSection !== child.props.id,
                    'text-gray-900': !child.props.disabled,
                    'cursor-not-allowed text-gray-400': child.props.disabled
                  },
                  child.props.className
                )}
                onClick={() =>
                  !child.props.disabled && handleClick(child.props.id, child?.props.active)
                }>
                <span className="">{child.props.icon}</span>
                <span className="grow">{child.props.title}</span>
                <ChevronTopIcon
                  className={clsx('transition-all w-5 h-5 ', {
                    'rotate-90': activeSection !== child.props.id,
                    'rotate-180': activeSection === child.props.id,
                    'cursor-pointer stroke-gray-500': !child.props.disabled,
                    'cursor-not-allowed stroke-gray-300': child.props.disabled
                  })}
                />
              </button>
              <div
                className={` transition-all ${activeSection === child.props.id ? 'h-auto block' : 'h-0 hidden'}`}
                style={{
                  maxHeight: activeSection === child.props.id ? '2000px' : '0',
                  opacity: activeSection === child.props.id ? '1' : '0',
                  transform:
                    activeSection === child.props.id ? 'translateY(0)' : 'translateY(-10px)'
                }}>
                {child.props.children}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

interface CollapseSectionProps {
  children: ReactNode;
  icon?: ReactNode;
  title: string | ReactNode;
  id: string;
  className?: string;
  active?: boolean;
  disabled?: boolean;
}
const CollapseSection = ({ children, className, id }: CollapseSectionProps) => {
  return (
    <div id={id} className={className}>
      {children}
    </div>
  );
};

export { Collapse, CollapseSection };
