export const languages = [
  { id: 1, label: 'Arabic', value: 'ar' },
  { id: 2, label: 'Argentinian', value: 'es-AR' },
  { id: 3, label: 'Catalan', value: 'ca' },
  { id: 4, label: 'Chinese Cantonese', value: 'yue' },
  { id: 5, label: 'Chinese Mandarin', value: 'cmn' },
  { id: 6, label: "Chinese, Tradt'l Script", value: 'zh-Hant' },
  { id: 7, label: 'Chinese, Simplified', value: 'zh-Hans' },
  { id: 7, label: 'Chinese, Taiwan', value: 'zh-TW' },
  { id: 8, label: 'Czech', value: 'cs' },
  { id: 9, label: 'Danish', value: 'da' },
  { id: 10, label: 'Dutch', value: 'nl' },
  { id: 11, label: 'English', value: 'en' },
  { id: 12, label: 'Farsi', value: 'fa' },
  { id: 13, label: 'Finnish', value: 'fi' },
  { id: 14, label: 'French, Canadian', value: 'fr-CA' },
  { id: 15, label: 'French', value: 'fr' },
  { id: 16, label: 'French, Parisian', value: 'fr-FR' },
  { id: 17, label: 'Gaelic, Scottish', value: 'gd' },
  { id: 18, label: 'German', value: 'de' },
  { id: 19, label: 'Greek', value: 'el' },
  { id: 21, label: 'Hebrew', value: 'he' },
  { id: 22, label: 'Hindi', value: 'hi' },
  { id: 23, label: 'Hungarian', value: 'hu' },
  { id: 24, label: 'Icelandic', value: 'is' },
  { id: 25, label: 'Indonesian', value: 'id' },
  { id: 26, label: 'Inuktitut', value: 'iu' },
  { id: 27, label: 'Irish', value: 'ga' },
  { id: 28, label: 'Italian', value: 'it' },
  { id: 29, label: 'Japanese', value: 'ja' },
  { id: 30, label: 'Kazakh', value: 'kk' },
  { id: 31, label: 'Korean', value: 'ko' },
  { id: 32, label: 'Malay', value: 'ms' },
  { id: 33, label: 'Maori', value: 'mi' },
  { id: 34, label: 'Nepali', value: 'ne' },
  { id: 35, label: 'Norwegian', value: 'no' },
  { id: 36, label: 'Polish', value: 'pl' },
  { id: 37, label: 'Portuguese, Brazilian', value: 'pt-BR' },
  { id: 38, label: 'Portuguese, European', value: 'pt-PT' },
  { id: 39, label: 'Portuguese', value: 'pt' },
  { id: 40, label: 'Punjabi', value: 'pa' },
  { id: 41, label: 'Romanian', value: 'ro' },
  { id: 42, label: 'Russian', value: 'ru' },
  { id: 43, label: 'Serbian', value: 'sr' },
  { id: 44, label: 'Sinhala', value: 'si' },
  { id: 45, label: 'Slovenian', value: 'sl' },
  { id: 46, label: 'Spanish', value: 'es' },
  { id: 47, label: 'Spanish, Castilian', value: 'es-ES' },
  { id: 48, label: 'Spanish, Latin American', value: 'es-419' },
  { id: 49, label: 'Swedish', value: 'sv' },
  { id: 50, label: 'Tagalog', value: 'tl' },
  { id: 51, label: 'Tamil', value: 'ta' },
  { id: 52, label: 'Thai', value: 'th' },
  { id: 53, label: 'Turkish', value: 'tr' },
  { id: 54, label: 'Ukrainian', value: 'uk' },
  { id: 55, label: 'Urdu', value: 'ur' },
  { id: 56, label: 'Vietnamese', value: 'vi' },
  { id: 57, label: 'Undefined', value: 'und' }
];

/**
 * Retrieves the display name of a language based on its culture code.
 * @param {string} cultureCode - The culture code to look up.
 * @param {'empty' | 'culture'} [fallbackOption='culture'] - Defines the behavior when the culture code is not found.
 *   'empty': Returns an empty string if the culture code is not found.
 *   'culture': Returns the input culture code if it is not found.
 * @returns {string} The display name of the language or the fallback value.
 */
export const getLanguageDisplayName = (
  cultureCode: string,
  fallbackOption: 'empty' | 'culture' = 'culture'
): string => {
  // Find the language that matches the given culture code
  const matchedLanguage = languages.find((language) => language.value === cultureCode);

  // Return the display name if found, otherwise apply fallback logic
  return matchedLanguage?.label || (fallbackOption === 'empty' ? '' : cultureCode);
};

export const audioLanguages = languages
  .concat([
    { id: 58, label: 'Music and Effects', value: 'x-me' },
    { id: 59, label: 'English Descriptive Audio', value: 'en-x-da' }
  ])
  .toSorted((a, b) => a.label.localeCompare(b.label));

export const subtitleLanguages = languages
  .concat([{ id: 58, label: 'English Closed Captions', value: 'ecc' }])
  .toSorted((a, b) => a.label.localeCompare(b.label));
