import React, { useEffect, useState, lazy } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';
import { omitBy } from 'lodash';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import HomeIcon from '../../assets/icons/home.svg?react';

import { Link, Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { getUsers } from '../../features/users/usersApi';
import { setUsers } from '../../features/users/usersSlice';
import { getOrganizations } from '../../features/organizations/organizationsApi';
import { setOrganizations } from '../../features/organizations/organizationsSlice';
import UsersPage from './UsersPage';

const Header = lazy(() => import('../../components/shared/Header'));
const OrganizationsPage = lazy(() => import('./OrganizationsPage'));
const MyDetailsPage = lazy(() => import('./MyDetailsPage'));
const OrganizationPage = lazy(() => import('./OrganizationPage'));
const Tag = lazy(() => import('../../components/ui/Tag'));

export default function SettingsRouter() {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUserGroups = useAppSelector(selectUserGroups);
  const [searchParams, setSearchParams] = useSearchParams();
  const organizations = useSelector((state: RootState) => state.organizations);
  const users = useSelector((state: RootState) => state.users);
  const [loading, setLoading] = useState<{ users: boolean; organizations: boolean }>({
    users: false,
    organizations: false
  });
  const [page, setPage] = useState<number>(parseInt((searchParams.get('page') || 1) as string));
  const [pageSize] = useState<number>(parseInt((searchParams.get('pageSize') || 100) as string));
  const disablePaginationFor = ['/settings'];
  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Settings'
    }
  ];

  interface settingsLinkItem {
    url: string;
    title: string;
    userGroups: string[];
    count?: number;
  }

  const settingsLinks: settingsLinkItem[] = [
    {
      url: '/settings',
      title: 'My Details',
      userGroups: ['basic']
    },
    {
      url: '/settings/organizations',
      title: 'Organizations',
      userGroups: ['above_admin']
    },
    {
      url: '/settings/users',
      title: 'Users',
      userGroups: ['above_admin']
    }
    // {
    //   url: '/settings/plan',
    //   title: 'Plan',
    //   userGroups: ['basic']
    // },
    // {
    //   url: '/settings/billing',
    //   title: 'Billing',
    //   userGroups: ['basic']
    // },
    // {
    //   url: '/settings/email',
    //   title: 'Email',
    //   userGroups: ['basic']
    // },
    // {
    //   url: '/settings/notifications',
    //   title: 'Notifications',
    //   userGroups: ['basic']
    // },
    // {
    //   url: '/settings/integrations',
    //   title: 'Integrations',
    //   userGroups: ['basic']
    // },
    // {
    //   url: '/settings/api',
    //   title: 'API',
    //   userGroups: ['basic']
    // }
  ].filter(
    (item) =>
      currentUserGroups && currentUserGroups.some((group) => item.userGroups.includes(group))
  );

  useEffect(() => {
    const query: {
      page?: number;
      pageSize?: number;
    } = omitBy(
      {
        page,
        pageSize
      },
      (value) => value === undefined
    );
    if (Object.keys(query).length !== 0 && disablePaginationFor.indexOf(location.pathname) === -1) {
      setSearchParams(query as URLSearchParams);
    }
  }, [page, pageSize]);

  const renderLink = (item: settingsLinkItem) => {
    let count = 0;

    if (item.title === 'Users' && !loading.users) {
      count = users.meta.total;
    }

    if (item.title === 'Organizations' && !loading.organizations) {
      count = organizations.meta.total;
    }

    return (
      <Link
        key={item.title}
        to={item.url}
        className={`flex flex-row gap-1 items-center ${
          location.pathname.indexOf(item.url) !== -1 &&
          item.url !== '/settings' &&
          'border-primary-700 border-b-2 text-primary-700'
        }`}>
        {item.title}
        {count > 0 && (
          <Tag size="modern" className="rounded drop-shadow-sm" variant="modern">
            {count}
          </Tag>
        )}
      </Link>
    );
  };

  const getData = () => {
    setLoading({
      users: true,
      organizations: true
    });
    getUsers({ page, pageSize }, (result) => {
      dispatch(setUsers(result));
      setLoading({
        ...loading,
        users: false
      });
    });

    let orgPage = page;
    let orgPageSize = pageSize;
    // check if is a user list page or not. and there is no way to know other
    // than this as the router is in the same level as the API call. maybe is needs a refactoring on the future
    if (window.location.href.indexOf('/users') !== -1) {
      orgPage = 1;
      orgPageSize = 1000;
    }

    getOrganizations({ page: orgPage, pageSize: orgPageSize }, (result) => {
      dispatch(setOrganizations(result));
      setLoading({
        ...loading,
        organizations: false
      });
    });
  };

  useEffect(() => {
    getData();
  }, [page, pageSize]);

  return (
    <>
      <Header />
      <Breadcrumbs items={breadcrumbItems} />
      <div className="bg-white mb-32 container mx-auto border-2 border-gray-200 rounded-xl">
        <div className="p-5 flex flex-row gap-4 items-center">
          <div className="grow">
            <h3 className="font-semibold text-lg text-gray-900">Settings</h3>
          </div>
        </div>
        <div className="flex flex-row gap-6 border-b border-gray-200 text-gray-500 font-semibold text-sm m-4 mt-0 leading-10">
          {settingsLinks.map((item) => renderLink(item))}
        </div>
        <div className="relative flex flex-col divide-y divide-gray-100">
          <Routes>
            <Route path="" element={<MyDetailsPage />} />
            {currentUserGroups && currentUserGroups.includes('above_admin') && (
              <>
                <Route
                  path="users"
                  element={
                    <UsersPage loading={loading} page={page} setPage={setPage} getData={getData} />
                  }
                />
                <Route
                  path="organizations"
                  element={
                    <OrganizationsPage
                      loading={loading}
                      page={page}
                      setPage={setPage}
                      getData={getData}
                    />
                  }
                />
                <Route
                  path="organizations/:orgId"
                  element={
                    <OrganizationPage
                      loading={loading}
                      page={page}
                      setPage={setPage}
                      getData={getData}
                    />
                  }
                />
              </>
            )}
            <Route path="*" element={<Navigate to="/settings" replace />} />
          </Routes>
        </div>
      </div>
    </>
  );
}
