import type { Blocker } from 'react-router-dom';
import { AlertDialog as AlertDialogPrimitive } from 'radix-ui';
import Button from './Button';

const ConfirmNavigationDialog = ({ blocker }: { blocker: Blocker }) => (
  <AlertDialogPrimitive.Root open={blocker.state === 'blocked'}>
    <AlertDialogPrimitive.Portal>
      <AlertDialogPrimitive.Overlay className="fixed inset-0 bg-black/50" />
      <AlertDialogPrimitive.Content className="fixed w-full max-w-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md bg-white p-5 text-gray-900 shadow">
        <AlertDialogPrimitive.Title className="mb-3 text-xl font-semibold">
          Are you sure?
        </AlertDialogPrimitive.Title>
        <AlertDialogPrimitive.Description className="mb-5 text-sm text-gray-600 text-muted-foreground">
          There is unsaved data on this page. Are you sure you want to leave?
        </AlertDialogPrimitive.Description>
        <div className="flex gap-4 justify-end">
          <AlertDialogPrimitive.Cancel asChild>
            <Button variant="default" rounded onClick={() => blocker.reset?.()}>
              Cancel
            </Button>
          </AlertDialogPrimitive.Cancel>
          <AlertDialogPrimitive.Action asChild>
            <Button variant="danger" rounded onClick={() => blocker.proceed?.()}>
              Confirm
            </Button>
          </AlertDialogPrimitive.Action>
        </div>
      </AlertDialogPrimitive.Content>
    </AlertDialogPrimitive.Portal>
  </AlertDialogPrimitive.Root>
);

export default ConfirmNavigationDialog;
