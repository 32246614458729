import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { VariantProps, cva } from 'class-variance-authority';

/*
Tag Component
How to use it:
  <Tag variant="primary" size="sm">
    Primary
  </Tag>
  <Tag variant="danger" size="sm">
    Danger
  </Tag>
  <Tag size="sm">default</Tag>
  <Tag variant="pending" size="sm">
    Pending
  </Tag>
  <Tag variant="success" size="sm">
    Success
  </Tag>
  <Tag variant="warning" size="sm">
    Warning
  </Tag>
 */

const tagStyle = cva(['flex flex-row gap-1 items-center justify-center transition'], {
  variants: {
    variant: {
      success: ['bg-success-100 border border-success-300 text-success-700'],
      default: ['bg-gray-50 border border-gray-200 text-gray-700'],
      error: ['bg-danger-50 border border-danger-200 text-danger-700'],
      primary: ['bg-primary border border-primary text-white'],
      warning: ['bg-warning-50 border border-warning-200 text-warning-700'],
      secondary: ['bg-gray border border-gray text-white'],
      info: ['bg-gray-50 border border-gray-200 text-gray-700'],
      modern: ['bg-white border border-gray-300 text-gray-700'],
      actionable: ['bg-primary-50 border border-primary-200 text-primary-700']
    },
    size: {
      modern: ['text-xs py-0.5 px-1'],
      xs: ['text-xs py-1 px-2'],
      sm: ['text-sm py-2 px-3.5'],
      md: ['text-md py-2.5 px-4'],
      lg: ['text-lg py-2.5 px-4.5'],
      xl: ['text-xl py-3 px-5'],
      '2xl': ['text-2xl py-4 px-7']
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'md'
  }
});

export interface TagProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof tagStyle> {
  block?: boolean;
}

export const Tag: React.FC<TagProps> = ({ className, variant, size, block, ...props }) => {
  const tagClasses = clsx(
    {
      'rounded-full': true,
      'w-full': block
    },
    className
  );
  return (
    <span className={twMerge(tagStyle({ variant, size, className: tagClasses }))} {...props} />
  );
};

export default Tag;
