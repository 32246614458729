import React, { RefObject, useEffect, useRef, useState } from 'react';
import Header from '../../components/shared/Header';
import CatalogFilters from '../../components/catalog-filters';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import MovieIcon from '../../assets/icons/movie.svg?react';
import SeriesIcon from '../../assets/icons/series.svg?react';
import PlusIcon from '../../assets/icons/plus.svg?react';
import EpisodeIcon from '../../assets/icons/episode.svg?react';
import HelpIcon from '../../assets/icons/help.svg?react';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import SearchIcon from '../../assets/icons/search.svg?react';
import FiltersIcon from '../../assets/icons/filters.svg?react';
import ShortIcon from '../../assets/icons/short.svg?react';
import DocumentaryIcon from '../../assets/icons/documentary.svg?react';
import SpecialIcon from '../../assets/icons/special.svg?react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Edit, Language, Meta, CatalogTitle } from '../../features/catalog/types';
import Select from '../../components/ui/Select';
import { Tooltip } from '../../components/ui/Tooltip';
import clsx from 'clsx';
import Skeleton from '../../components/ui/Skeleton';
import { setSearchTitles } from '../../features/catalog/catalogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { searchCatalog } from '../../features/catalog/catalogApi';
import Result from '../../components/ui/Result';
import Tag from '../../components/ui/Tag';
import useLocalStorage from '../../common/hooks/useLocalStorage';
import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';
import { Helmet } from 'react-helmet-async';
import { useUserContext } from '../../common/providers/UserProvider';
import { TitleLabel } from '../../components/shared/TitleLabel';

interface EditsMap {
  [key: string]: {
    dubs_accepted?: string[][];
    subs_accepted?: string[][];
    dubs_new?: string[][];
    subs_new?: string[][];
  };
}

export const renderEdits = (item: CatalogTitle) => {
  const editsMap: EditsMap = {};
  item.edits &&
    item.edits.forEach((edit: Edit) => {
      if (!editsMap[edit.type]) {
        editsMap[edit.type] = {};
      }
      editsMap[edit.type].dubs_accepted = [
        ...new Set(edit.dubbings.map((lang: Language) => `${lang.language}_${lang.status}`))
      ]
        .map((item) => item.split('_'))
        .filter((item) => item[1] === 'ACCEPTED')
        .sort();
      editsMap[edit.type].dubs_new = [
        ...new Set(edit.dubbings.map((lang: Language) => `${lang.language}_${lang.status}`))
      ]
        .map((item) => item.split('_'))
        .filter((item) => item[1] !== 'ACCEPTED')
        .sort();

      editsMap[edit.type].subs_accepted = [
        ...new Set(edit.subtitles.map((lang: Language) => `${lang.language}_${lang.status}`))
      ]
        .map((item) => item.split('_'))
        .filter((item) => item[1] === 'ACCEPTED')
        .sort();

      editsMap[edit.type].subs_new = [
        ...new Set(edit.subtitles.map((lang: Language) => `${lang.language}_${lang.status}`))
      ]
        .map((item) => item.split('_'))
        .filter((item) => item[1] !== 'ACCEPTED')
        .sort();
    });

  return (
    <div className="text-gray-500">
      {Object.keys(editsMap).map((key, index) => {
        return (
          (editsMap[key].dubs_accepted?.length ||
            0 > 0 ||
            editsMap[key].dubs_new?.length ||
            0 > 0 ||
            editsMap[key].subs_new?.length ||
            0 > 0 ||
            editsMap[key].subs_accepted?.length ||
            0 > 0) && (
            <div
              key={key}
              className={`flex flex-row gap-2 items-center ${index > 0 ? 'mt-1' : ''}`}>
              <span className="font-bold">{key}</span>
              <span className="font-bold">|</span>

              <span className="flex flex-row items-center gap-2">
                {(editsMap[key].subs_accepted?.length ||
                  0 > 0 ||
                  editsMap[key].subs_new?.length ||
                  0 > 0) && (
                  <span className="flex flex-wrap gap-1 items-center">
                    <span>subs: </span>
                    {(editsMap[key].subs_accepted?.length || 0 > 0) && (
                      <Tag
                        key={`${key}-subs-${index}`}
                        variant="success"
                        size="xs"
                        className={`rounded-none uppercase p-1`}>
                        {editsMap[key].subs_accepted?.map((item: string[]) => item[0]).join(', ')}
                      </Tag>
                    )}
                    {(editsMap[key].subs_new?.length || 0 > 0) && (
                      <Tag
                        key={`${key}-subs-new-${index}`}
                        variant="default"
                        size="xs"
                        className={`rounded-none uppercase p-1`}>
                        {editsMap[key].subs_new?.map((item: string[]) => item[0]).join(', ')}
                      </Tag>
                    )}
                  </span>
                )}
                {(editsMap[key].dubs_accepted?.length ||
                  0 > 0 ||
                  editsMap[key].dubs_new?.length ||
                  0 > 0) && (
                  <span className="flex flex-wrap gap-1 items-center">
                    <span>dubs: </span>
                    {(editsMap[key].dubs_accepted?.length || 0 > 0) && (
                      <Tag
                        key={`${key}-dubs-${index}`}
                        variant="success"
                        size="xs"
                        className={`rounded-none uppercase p-1`}>
                        {editsMap[key].dubs_accepted?.map((item: string[]) => item[0]).join(', ')}
                      </Tag>
                    )}
                    {(editsMap[key].dubs_new?.length || 0 > 0) && (
                      <Tag
                        key={`${key}-dubs-new-${index}`}
                        variant="default"
                        size="xs"
                        className={`rounded-none uppercase p-1`}>
                        {editsMap[key].dubs_new?.map((item: string[]) => item[0]).join(', ')}
                      </Tag>
                    )}
                  </span>
                )}
              </span>
            </div>
          )
        );
      })}
    </div>
  );
};

export default function CatalogPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { results, meta } = useSelector((state: RootState) => state.catalog);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filtersInitiated, setFiltersInitiated] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [catalogSearch, setCatalogSearch] = useLocalStorage('catalogSearch', '');
  const queryParams = Object.fromEntries(searchParams.entries());
  const queryRef = useRef<HTMLInputElement>();
  const currentUserGroups = useAppSelector(selectUserGroups);
  const { sourcesOptions } = useUserContext();
  const cleanedQueryParams = Object.fromEntries(
    Object.entries(queryParams).filter(([key, value]) => value !== '')
  );

  const getSource = () => {
    if (sourcesOptions && sourcesOptions.length === 1) {
      return sourcesOptions[0]?.value;
    }
    return sourcesOptions.find((item) => item.value === 'WBTVD')?.value || ''; // in the future with more catalogs we should think of a different way
  };

  const {
    seasonNumber,
    seriesId,
    source = getSource(),
    search,
    sortOrder,
    sortField,
    page,
    titleType
  } = cleanedQueryParams;

  const iconsMap = {
    SERIES: <SeriesIcon className="stroke-gray-300" />,
    MOVIE: <MovieIcon className="stroke-gray-300" />,
    EPISODE: <EpisodeIcon className="stroke-gray-300" />,
    SHORT: <ShortIcon className="stroke-gray-300" />,
    DOCUMENTARY: <DocumentaryIcon className="fill-gray-300" />,
    SPECIAL: <SpecialIcon className="fill-gray-300" />
  };

  const columns = [
    {
      key: 'name',
      title: 'Title',
      allowSorting: true,
      render: (item: CatalogTitle) => (
        <div className="text-gray font-semibold text-sm flex flex-row gap-4 items-center">
          {iconsMap[item.type]}
          <div className="flex flex-col">
            <TitleLabel
              catalogTitle={item}
              seriesOnClick={() => {
                setSearchParams({ seriesId: item.series?._id || '', source });
              }}
              seasonOnClick={() => {
                setSearchParams({
                  seasonNumber: item.seasonNumber?.toString() || '',
                  seriesId: item.series?._id.toString() || '',
                  page: '1',
                  source
                });
                if (queryRef.current) {
                  queryRef.current.value = '';
                }
              }}
            />
            {item.eidrId && (
              <div className="text-gray-10 text-sm font-light pt-1">{item.eidrId}</div>
            )}
          </div>
        </div>
      )
    },
    {
      key: 'releaseDate',
      title: 'Year',
      allowSorting: false,
      className: 'w-16',
      render: (item: CatalogTitle) => (
        <span className="text-gray text-sm">{(item.releaseDate || '').substring(0, 4)}</span>
      )
    },
    {
      key: 'edits',
      title: 'Edits',
      className: 'w-2/6',
      renderTitle: () => (
        <span className="flex flex-row gap-2">
          Edits{' '}
          <Tooltip
            content={
              <div className="text-gray-500 text-xs flex flex-col gap-2">
                <div className="text-gray-700 font-semibold text-sm">EDITS LEGEND</div>
                <div className="flex flex-col gap-1">
                  <div>
                    <span className="font-semibold">TH</span> (Theatrical)
                  </div>
                  <div>
                    <span className="font-semibold">ED</span> (AirlineEdit)
                  </div>
                  <div>
                    <span className="font-semibold">ME</span> (General Middle East Edit)
                  </div>
                  <div>
                    <span className="font-semibold">SAU</span> (Saudi Edit)
                  </div>
                </div>
              </div>
            }>
            <HelpIcon />
          </Tooltip>
        </span>
      ),
      render: (item: CatalogTitle) => renderEdits(item)
    },
    {
      key: '_id',
      title: '',
      render: (item: CatalogTitle) => {
        if (item.type !== 'SERIES') {
          return (
            <div className="w-14 float-right">
              {currentUserGroups &&
                currentUserGroups.some((group) =>
                  ['above_admin', 'above_ops', 'csp_user', 'csp_admin'].includes(group)
                ) && (
                  <Button
                    rounded
                    className="bg-white border-none focus:shadow-none active:shadow-none"
                    href={`/catalog/order/${item._id}?source=${source}`}>
                    <PlusIcon className="stroke-primary" />
                  </Button>
                )}
            </div>
          );
        } else {
          return (
            <div className="">
              <Select
                placeholder="Season"
                onChange={(option: any) => {
                  setSearchParams({
                    ...searchParams,
                    seasonNumber: option.value || '',
                    seriesId: item._id || '',
                    page: '1',
                    source
                  });
                  if (queryRef.current) {
                    queryRef.current.value = '';
                  }
                }}
                options={item.seasonNumbers?.map((season) => ({
                  value: season.toString(),
                  label: `Season ${season}`
                }))}
              />
            </div>
          );
        }
      }
    }
  ];

  useEffect(() => {
    if (location.search) {
      const queryKeys = Object.keys(cleanedQueryParams);
      if (queryKeys.indexOf('seasonNumber') !== -1) {
        cleanedQueryParams.pageSize = '100';
      }
      if (queryKeys.indexOf('search') !== -1 && queryRef.current) {
        queryRef.current.value = cleanedQueryParams.search;
      }
      if (queryKeys.length > 1 && queryKeys.indexOf('source') !== -1) {
        setFiltersInitiated(true);
        setIsLoading(true);
        searchCatalog(
          cleanedQueryParams,
          (payload: { results: CatalogTitle[] | null; meta: Meta | null }) => {
            dispatch(setSearchTitles(payload));
            setIsLoading(false);
          }
        );
      } else {
        setFiltersInitiated(false);
      }
    }
  }, [location.search]);

  const onSearch = (query: string) => {
    setSearchParams({ ...queryParams, search: query, page: '1', source });
    setCatalogSearch(query);
  };

  const onFilter = (value: string) => {
    setSearchParams({ ...queryParams, titleType: value, page: '1', source });
  };

  const onSource = (value: string) => {
    setSearchParams({
      source: value
    });
  };

  const renderPageTitle = () => {
    if (
      searchParams.get('seriesId') &&
      !cleanedQueryParams.seasonNumber &&
      results &&
      results.length > 0 &&
      !isLoading
    ) {
      return (
        <>
          <Button
            onClick={() => {
              setSearchParams({
                ...cleanedQueryParams,
                seriesId: '',
                seasonNumber: '',
                search: catalogSearch,
                source
              });
            }}
            className="text-primary underline px-0"
            link>
            Search Results
          </Button>
          <ArrowRightIcon className="w-4 h-4 mt-1 stroke-gray-400" />
          {results[0].series?.name}
        </>
      );
    }
    if (seriesId && seasonNumber && results && results.length > 0 && !isLoading) {
      return (
        <>
          <Button
            onClick={() => {
              setSearchParams({
                ...cleanedQueryParams,
                seriesId: '',
                seasonNumber: '',
                search: catalogSearch,
                source
              });
            }}
            className="text-primary underline px-0"
            link>
            Search Results
          </Button>
          <ArrowRightIcon className="w-4 h-4 mt-1 stroke-gray-400" />
          <Button
            onClick={() =>
              setSearchParams({
                ...cleanedQueryParams,
                seasonNumber: '',
                source
              })
            }
            className="text-primary underline px-0"
            link>
            {results[0].series?.name}
          </Button>
          <ArrowRightIcon className="w-4 h-4 mt-1 stroke-gray-400" />
          Season {results[0].seasonNumber}
        </>
      );
    }
    return <h3 className=" text-lg text-gray-900">Titles Catalog</h3>;
  };

  return (
    <>
      <Helmet>
        <title>Above Media | Catalog</title>
      </Helmet>
      <Header />
      <CatalogFilters
        filter={titleType}
        source={source}
        onSource={onSource}
        search={search}
        onSearch={onSearch}
        onFilter={onFilter}
        queryRef={queryRef as RefObject<HTMLInputElement>}
      />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl mb-8">
        {filtersInitiated && (
          <div className="p-5 flex flex-row gap-4 items-center justify-center">
            <div className="grow gap-2 flex flex-row items-center font-semibold text-lg text-gray-900">
              {renderPageTitle()}
            </div>
            <div className="flex-none flex flex-row gap-4 items-center">
              {seasonNumber !== undefined && (
                <div
                  className={clsx('flex flex-row gap-4 items-center', {
                    'opacity-60': selectedRows.size === 0
                  })}>
                  {currentUserGroups &&
                    currentUserGroups.some((group) =>
                      ['above_admin', 'above_ops', 'csp_user', 'csp_admin'].includes(group)
                    ) && (
                      <>
                        <h3 className="font-semibold text-sm text-gray-900">With selected</h3>
                        <Button
                          disabled={selectedRows.size === 0}
                          href={`/catalog/order/${Array.from(selectedRows).join(
                            '-'
                          )}/?source=${source}`}
                          rounded
                          variant="primary"
                          className="flex flex-row gap-1">
                          <PlusIcon className="stroke-white fill-white" /> Create Order
                        </Button>
                      </>
                    )}
                </div>
              )}
              {/* <Button
              rounded
              className="px-1 py-3.5 bg-transparent border-0 focus:shadow-none active:shadow-none">
              <VDotsIcon />
            </Button> */}
            </div>
          </div>
        )}
        <div className="relative">
          {!isLoading && !filtersInitiated && (
            <Result
              title="Search for a title"
              description="Select one of the filters above to see the titles results."
              icon={<FiltersIcon className="w-8 h-8 stroke-gray-400" />}
            />
          )}
          {isLoading && filtersInitiated && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-0"></div>
              <div className="border-t border-gray-100">
                <Skeleton />
              </div>
            </>
          )}

          {!isLoading && filtersInitiated && (
            <Table
              data={results || []}
              columns={columns}
              multipleSelection={seasonNumber !== undefined}
              rowId="_id"
              sortDirection={sortOrder}
              sortedColumn={sortField}
              setSortDirection={(value: string) =>
                setSearchParams({ ...queryParams, sortOrder: value })
              }
              setSortedColumn={(value: string) => {
                setSearchParams({ ...queryParams, sortField: value });
              }}
              onSelectChange={(keys) => setSelectedRows(keys)}
              disabledRows={(results || [])
                .filter((item) => item.type === 'SERIES')
                .map((item) => item._id)}
              pagination={{
                totalItems: meta?.total || 0,
                itemsPerPage: 30,
                currentPage: parseInt(page),
                onPageChange: (page) => setSearchParams({ ...queryParams, page: page.toString() })
              }}
              noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
              noDataLabel="No titles were found!"
              noDataDescription="Your search did not match any title. Please try again later."
            />
          )}
        </div>
      </div>
    </>
  );
}
