import { graphql } from 'gql.tada';

export const getOrganization = graphql(`
  query getOrganization($orgId: String) {
    getOrganizationByOrgId(orgId: $orgId) {
      createdAt
      _id
      clients {
        encodeSystems {
          ifeFormat
          ifeSystem
          poDestination
          vtkTemplate
        }
        clientId
        name
        territory
        orderGroupYearMonthRequired
      }
      mezzaninePoDestinations
      modifiedAt
      name
      orderTypes
      orgId
      sourceCatalogs
      billingPrefix
    }
  }
`);

export const getOrganizations = graphql(`
  query getOrganizations(
    $page: Int = 1
    $pageSize: Int = 30
    $sortOrder: SortOrder = null
    $sortField: String = "_id"
  ) {
    listOrganizations(
      page: $page
      pageSize: $pageSize
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      results {
        _id
        name
        orgId
        orderTypes
        sourceCatalogs
        clients {
          name
          clientId
          territory
          encodeSystems {
            ifeSystem
            ifeFormat
            vtkTemplate
            poDestination
          }
          orderGroupYearMonthRequired
        }
        mezzaninePoDestinations
        createdAt
        modifiedAt
      }
      meta {
        page
        pageSize
        total
      }
    }
  }
`);
