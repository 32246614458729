import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import App from './App';
import Store from './common/providers/Store';
import { UserProvider } from './common/providers/UserProvider';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import { NODE_ENV } from './common/constants';

function main() {
  const content = (
    <HelmetProvider>
      <UserProvider>
        <Store>
          <Toaster
            toastOptions={{
              duration: 5000,
              success: {
                className: 'bg-primary text-sm text-white'
              },
              error: {
                className: 'bg-danger text-sm text-white'
              }
            }}>
            {(t) => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <>
                    {icon}
                    {message}
                    {t.type !== 'loading' && <button onClick={() => toast.dismiss(t.id)}>X</button>}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster>
          <App />
        </Store>
      </UserProvider>
    </HelmetProvider>
  );

  const router = createBrowserRouter(
    createRoutesFromElements(<Route path="*" element={content} />)
  );

  if (NODE_ENV === 'dev') {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    );
  } else {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <RouterProvider router={router} />
    );
  }
}
main();
