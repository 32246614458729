import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
const Header = lazy(() => import('../../components/shared/Header'));
const RequireUserGroups = lazy(() => import('../../common/providers/RequireUserGroups'));
const IngestConfirmationPage = lazy(() => import('./IngestConfirmationPage'));
const IngestContentMetadataPage = lazy(() => import('./IngestContentMetadataPage'));
const IngestFileLocationsPage = lazy(() => import('./IngestFileLocationsPage'));
const ProcessDetailsPage = lazy(() => import('./ProcessDetailsPage'));
const ProcessesPage = lazy(() => import('./ProcessesPage'));

export default function IngestionRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route
          path=""
          element={
            <RequireUserGroups groups={['basic']}>
              <ProcessesPage />
            </RequireUserGroups>
          }
        />
        <Route
          path="process/:processId"
          element={
            <RequireUserGroups groups={['basic']}>
              <ProcessDetailsPage />
            </RequireUserGroups>
          }
        />
        <Route
          path="new/*"
          element={
            <RequireUserGroups groups={['basic']}>
              <Routes>
                <Route path="file-locations" element={<IngestFileLocationsPage />} />
                <Route path="content-metadata" element={<IngestContentMetadataPage />} />
                <Route path="confirmation" element={<IngestConfirmationPage />} />
              </Routes>
            </RequireUserGroups>
          }
        />
        <Route path="*" element={<Navigate to="/ingestion" replace />} />
      </Routes>
    </>
  );
}
