export interface Meta {
  all: number;
  documentaries: number;
  episodes: number;
  lastPage: number;
  movies: number;
  series: number;
  page: number;
  pageSize: number;
  shorts: number;
  specials: number;
  total: number;
}

export enum AssetStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  TO_CONFORM = 'TO_CONFORM',
  TO_DELETE = 'TO_DELETE',
  DELETED = 'DELETED',
  MISSING = 'MISSING'
}

export interface Language {
  language: string;
  status: AssetStatus;
}

export interface Edit {
  repositoryItemId: string;
  type: string;
  dubbings: Language[];
  subtitles: Language[];
}

export interface Series {
  _id: string;
  eidrId: string;
  name: string;
}

export interface CatalogTitle {
  _id: string;
  name: string;
  releaseDate: string;
  type: 'EPISODE' | 'MOVIE' | 'SERIES';
  mpmNumber: string | null;
  eidrId: string | null;
  sourceCatalog: keyof typeof SourceCatalog | null;
  seasonNumbers: number[] | null;
  edits: Edit[];
  episodeNumber: number | null;
  seasonNumber: number | null;
  series?: Series;
}

export interface GetTitlesResponse {
  searchCatalog: {
    results: CatalogTitle[];
    meta: Meta | null;
  };
}

export interface System {
  vtkTemplate: string;
  value: string;
  label: string;
  ifeSystem: string;
  ifeFormat: string;
  id: number;
  filename: string;
  poDestination: string;
}

export enum SourceCatalog {
  ABOVE = 'ABOVE',
  WBTVD = 'WBTVD',
  DISNEY = 'DISNEY'
}

export interface GetCatalogItemsResponse {
  listCatalogItemsByIds: CatalogTitle[];
}
