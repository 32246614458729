import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import SearchIcon from '../../assets/icons/search.svg?react';
import CheckIcon from '../../assets/icons/check.svg?react';
import XCircleIcon from '../../assets/icons/x-circle.svg?react';
import RefreshIcon from '../../assets/icons/refresh.svg?react';
import VDotsIcon from '../../assets/icons/v-dots.svg?react';
import DownloadIcon from '../../assets/icons/download.svg?react';
import {
  approveOrder,
  bulkApproveOrder,
  bulkRejectOrder,
  cancelOrderDelivery,
  getOrderGroups,
  getOrders,
  OnCreateOrderMutations,
  onCreateOrderSubscription,
  OnUpdateOrderMutations,
  onUpdateOrderSubscription,
  rejectOrder,
  restartOrderDelivery
} from '../../features/orders/ordersApi';
import { Link, useSearchParams } from 'react-router-dom';
import Skeleton from '../../components/ui/Skeleton';
import OrdersSearch from '../../components/orders-search';
import {
  ORDER_DELIVERY_STATUS_METADATA,
  ORDER_LICENSE_STATUS_METADATA,
  Order,
  OrderGroup,
  OrderTitle
} from '../../features/orders/types';
import { formatDate, formatDateTime, formatDateTimeForPath } from '../../common/dates';
import { Tag } from '../../components/ui/Tag';
import {
  DerivedOrderDeliveryStatusLabels,
  Option,
  OrderLicenseStatusLabels
} from '../../common/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  appendOrder,
  setOrders,
  updateOrderDeliveryStatus,
  updateOrderStatuses
} from '../../features/orders/ordersSlice';
import { RootState } from '../../app/store';
import toast from 'react-hot-toast';
import { groupBy, padStart } from 'lodash';
import { Popover, PopoverDropdownContent, PopoverTrigger } from '../../components/ui/Popover';
import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';
import { useUserContext } from '../../common/providers/UserProvider';
import { toCsvString } from '../../common/csv';
import { downloadFile } from '../../common/utils';
import { getOrganization } from '../../features/organizations/organizationsApi';
import { Organization } from '../../features/organizations/types';
import { getDerivedOrderDeliveryStatus } from '../../features/orders/utils';
import { GraphqlSubscriptionMessage } from '@aws-amplify/api-graphql';

const ActionSet = ({ getData, order }: { getData: () => void; order: Order }) => {
  const currentUserGroups = useAppSelector(selectUserGroups);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const onApprove = (_id: string) => {
    setIsOpen(false);
    setIsSubmitting(true);
    approveOrder(_id, () => {
      getData();
      setIsSubmitting(false);
    });
  };

  const onReject = (_id: string) => {
    setIsOpen(false);
    setIsSubmitting(true);
    rejectOrder(_id, () => {
      getData();
      setIsSubmitting(false);
    });
  };

  const onRestartOrderDelivery = (_id: string) => {
    setIsOpen(false);
    setIsSubmitting(true);
    restartOrderDelivery(_id, () => {
      getData();
      setIsSubmitting(false);
    });
  };

  const onCancelOrderDelivery = (_id: string) => {
    setIsOpen(false);
    setIsSubmitting(true);
    cancelOrderDelivery(_id, () => {
      dispatch(updateOrderDeliveryStatus({ _id, status: 'CANCELLED' }));
      getData();
      setIsSubmitting(false);
    });
  };

  return (
    <div className="flex flex-row gap-2">
      {!['NO_DELIVERY_NEEDED', 'CANCELLED', 'SUCCESS'].includes(order.deliveryStatus) && (
        <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
          <PopoverTrigger asChild>
            <Button variant="default" link rounded>
              <VDotsIcon className="stroke-gray w-5 h-5" />
            </Button>
          </PopoverTrigger>
          <PopoverDropdownContent align="end">
            <div className="divide-y divide-gray-100 min-w-max flex flex-col justify-start">
              {(currentUserGroups || []).some((group) =>
                ['studio_admin', 'above_admin'].includes(group)
              ) && (
                <>
                  {(order.licenseStatus === 'REJECTED' || order.licenseStatus === 'PENDING') && (
                    <Button
                      variant="default"
                      size="sm"
                      block
                      disabled={isSubmitting}
                      onClick={() => onApprove(order._id)}
                      className="border-0 bg-white justify-start outline-none">
                      <CheckIcon className="w-5 h-5 stroke-success" /> Approve
                    </Button>
                  )}
                  {order.licenseStatus === 'PENDING' && (
                    <Button
                      variant="default"
                      size="sm"
                      block
                      disabled={isSubmitting}
                      onClick={() => onReject(order._id)}
                      className="border-0 bg-white justify-start outline-none">
                      <XCircleIcon className="w-5 h-5 stroke-danger" /> Reject
                    </Button>
                  )}
                </>
              )}
              {currentUserGroups &&
                currentUserGroups.some((group) =>
                  ['above_admin', 'above_ops', 'csp_user', 'csp_admin'].includes(group)
                ) && (
                  <>
                    {!['CANCELLED', 'SUCCESS'].includes(order.deliveryStatus) && (
                      <Button
                        variant="default"
                        size="sm"
                        block
                        disabled={isSubmitting}
                        onClick={() => onCancelOrderDelivery(order._id)}
                        className="border-0 bg-white justify-start outline-none">
                        <XCircleIcon className="w-5 h-5 stroke-danger" /> Cancel Delivery
                      </Button>
                    )}
                    {order.licenseStatus === 'APPROVED' &&
                      order.titles
                        .flatMap((t) => t.deliveries)
                        .filter((d) => !['CANCELLED', 'DELIVERY_SUCCESS'].includes(d.status))
                        .length > 0 && (
                        <Button
                          variant="default"
                          size="sm"
                          block
                          disabled={isSubmitting}
                          onClick={() => onRestartOrderDelivery(order._id)}
                          className="border-0 bg-white items-start justify-start outline-none">
                          <RefreshIcon className="w-5 h-5 stroke-gray-400" /> Restart Delivery
                        </Button>
                      )}
                  </>
                )}
            </div>
          </PopoverDropdownContent>
        </Popover>
      )}
    </div>
  );
};

export default function OrdersPage() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [orderGroupOptions, setOrderGroupsOptions] = useState<Option[]>([]);
  const [orderGroups, setOrderGroups] = useState<OrderGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userOrgsMap } = useUserContext();
  const { results, meta } = useSelector((state: RootState) => state.orders);
  const currentUserGroups = useAppSelector(selectUserGroups);

  const queryParams = Object.fromEntries(searchParams.entries());

  const cleanedQueryParams = Object.fromEntries(
    Object.entries(queryParams).filter(([key, value]) => value !== '')
  );

  const { licenseStatus, deliveryStatus, orderGroupId, page, search } = cleanedQueryParams;

  const getData = () => {
    setIsLoading(true);
    const orderGroup = orderGroups.find((group) => group._id === orderGroupId);
    if (orderGroup) {
      cleanedQueryParams.orderGroupClientId = orderGroup.clientId;
      cleanedQueryParams.orderGroupTitle = orderGroup.title;
      delete cleanedQueryParams.orderGroup;
    }
    getOrders(cleanedQueryParams, (result) => {
      setIsLoading(false);
      dispatch(setOrders(result));
    });
  };

  useEffect(() => {
    getData();
  }, [searchParams]);

  useEffect(() => {
    if (orderGroups.length && orderGroupId) {
      getData();
    }
  }, [orderGroups]);

  const renderItems = (titles: OrderTitle[]) => {
    const groupedTitles = groupBy(titles, (title) =>
      title.titleType === 'EPISODE' ? `${title.seriesName}-${title.seasonNumber}` : title.name
    );
    return Object.keys(groupedTitles).map((key: string) => {
      const [name, season] = key.split('-');
      const count = groupedTitles[key].length;
      return (
        <div key={key} className="p-1">
          {name}{' '}
          {season
            ? `-> S${padStart(season, 2, '0')} ${
                count === 1 ? `-> ${groupedTitles[key][0].name}` : `(${count} Titles)`
              }`
            : ''}
        </div>
      );
    });
  };

  const columns = [
    {
      key: 'id',
      title: 'Order Group',
      className: 'w-72',
      render: (order: Order) => (
        <div className="text-gray font-semibold text-sm flex flex-row gap-4 items-center">
          <Link to={`/orders/${order._id}`}>
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center gap-2 text-gray">
                {order.orderGroup.title} - {order.orderGroup.clientText}
              </div>
              <div className="text-gray-10 text-sm font-light">
                {userOrgsMap[order.organization].name}
              </div>
            </div>
          </Link>
        </div>
      )
    },
    {
      key: 'id',
      title: 'PO Nr.',
      className: 'w-32',
      render: (order: Order) => (
        <Button link href={`/orders/${order._id}`} className="text-primary justify-start p-0">
          {order.externalId}
        </Button>
      )
    },
    {
      key: 'creation_date',
      title: 'Creation Date',
      className: 'w-32',
      render: (order: Order) => (
        <div className="flex flex-col">
          <span className="text-gray text-sm">
            {formatDateTime(order.createdAt).split(', ')[0]}
          </span>
          <span className="text-gray-10 text-sm font-light">
            {formatDateTime(order.createdAt).split(', ')[1]}
          </span>
        </div>
      )
    },
    {
      key: 'title',
      title: 'Title',
      render: (order: Order) => (
        <div className="w-full overflow-hidden text-wrap flex flex-col divide-y divide-gray-100">
          {renderItems(order.titles)}
        </div>
      )
    },
    {
      key: 'licenseStatus',
      title: 'License Status',
      className: 'w-40',
      render: (order: Order) => (
        <Tag size="xs" variant={ORDER_LICENSE_STATUS_METADATA[order.licenseStatus]?.variant}>
          {OrderLicenseStatusLabels[order.licenseStatus]}
        </Tag>
      )
    },
    {
      key: 'deliveryStatus',
      title: 'Delivery Status',
      className: 'w-40',
      render: (order: Order) => {
        return (
          <Tag
            size="xs"
            variant={ORDER_DELIVERY_STATUS_METADATA[getDerivedOrderDeliveryStatus(order)]?.variant}>
            {DerivedOrderDeliveryStatusLabels[getDerivedOrderDeliveryStatus(order)]}
          </Tag>
        );
      }
    },
    {
      key: '_id',
      title: '',
      render: (order: Order) => (
        <div className="float-right">
          <ActionSet order={order} getData={getData} />
        </div>
      )
    }
  ];

  const onSearch = (values: Record<string, any>) => {
    setSearchParams({ ...values, page: '1' });
  };

  const onBulkApprove = () => {
    bulkApproveOrder(Array.from(selectedRows).map(String), () => {
      toast.success('Selected orders were approved successfully');
      getData();
    });
  };

  const onBulkReject = () => {
    bulkRejectOrder(Array.from(selectedRows).map(String), () => {
      toast.success('Selected orders were rejected successfully');
      getData();
    });
  };

  const onDownloadCsv = () => {
    const orderGroup = orderGroups.find((group) => group._id === orderGroupId);
    if (orderGroup) {
      cleanedQueryParams.orderGroupClientId = orderGroup.clientId;
      cleanedQueryParams.orderGroupTitle = orderGroup.title;
      delete cleanedQueryParams.orderGroup;
    }
    cleanedQueryParams['pageSize'] = cleanedQueryParams['page'] = '-1';

    // TODO: move the data sources integration to a backend query
    getOrders(cleanedQueryParams, async (result) => {
      // fetch all organizations data
      const orgIds = new Set<string>(result?.results.map((r) => r.organization));
      const organizations = new Map<string, Organization>();
      await Promise.all(
        [...orgIds].map((orgId) => getOrganization(orgId, (o) => o && organizations.set(orgId, o)))
      );

      // studio admin users require different columns
      const orders: Record<string, string | number>[] = [];
      if (currentUserGroups && currentUserGroups.includes('studio_admin')) {
        result?.results.forEach((order) => {
          const org = organizations.get(order.organization);
          const client = org?.clients.find((a) => a.clientId === order.orderGroup.clientId);

          order.titles.forEach((title) => {
            orders.push({
              Organization: org?.name || '',
              Client: order.orderGroup.clientText,
              Territory: client?.territory || '',
              'PO Number': order.externalId,
              Brand: order.brand,
              'Media Type': title.seriesName ? 'TV' : 'Film',
              'License Period Start': title.licenseStart,
              'License Period End': title.licenseEnd,
              'MPM Number': title.titleCatalogId,
              'Film/Series Title': title.seriesName || title.name,
              'Season Number': title.seasonNumber || '',
              'Episode Number': title.episodeNumber || '',
              'Episode Title': title.seriesName ? title.name : '',
              'License Fee': title.dealName === '' ? 'all access' : title.dealName
            });
          });
        });
      } else {
        result?.results.forEach((order) => {
          order.titles.forEach((title) => {
            orders.push({
              'Order Id': order._id,
              'Order Group': order.orderGroup.title,
              'Client Id': order.orderGroup.clientId,
              'Client Text': order.orderGroup.clientText,
              'External Id': order.externalId,
              'Creation Date': formatDate(order.createdAt),
              'Title Name': title.name,
              'Series Name': title.seriesName || '',
              'Season Number': title.seasonNumber || '',
              'Episode Number': title.episodeNumber || '',
              'Title Type': title.titleType,
              'License Status': order.licenseStatus,
              'Delivery Status': order.deliveryStatus,
              // 'Delivery Filename': title.deliveryFileName,
              // 'Audio Languages': (title.audioLanguages || []).join(', '),
              // 'Subtitle Languages': (title.subtitleLanguages || []).join(', '),
              // Destination: order.destination || '',
              Organization: order.organization
              // Status: title.status
            });
          });
        });
      }
      downloadFile(toCsvString(orders), `orders-export-${formatDateTimeForPath()}.csv`);
    })
      .then(() => {
        toast.success('Orders CSV downloaded successfully');
      })
      .catch(() => {
        toast.error('Failed to download orders CSV');
      });
  };

  useEffect(() => {
    getOrderGroups('', (result) => {
      setOrderGroups(result);
      setOrderGroupsOptions(
        result.map((group) => ({ value: group._id, label: `${group.title} - ${group.clientText}` }))
      );
    });
  }, []);

  useEffect(() => {
    const createOrderSubscriptions: { unsubscribe: () => void }[] = [];
    const updateOrderSubscriptions: { unsubscribe: () => void }[] = [];

    Object.keys(userOrgsMap).map((organization) => {
      createOrderSubscriptions.push(
        onCreateOrderSubscription(
          organization,
          (result: GraphqlSubscriptionMessage<OnCreateOrderMutations>) => {
            const order = result.data?.createOrder;
            console.log('subscription create order: ', order);
            if (order) {
              dispatch(appendOrder(order));
            }
          }
        )
      );
      updateOrderSubscriptions.push(
        onUpdateOrderSubscription(
          organization,
          undefined,
          (result: GraphqlSubscriptionMessage<OnUpdateOrderMutations>) => {
            const updatedOrder = result.data?.onUpdateOrder;
            console.log('subscription update: ', updatedOrder);
            if (updatedOrder) {
              dispatch(updateOrderStatuses(updatedOrder));
            }
          }
        )
      );
    });
    return () => {
      // unsubscribe from further updates
      createOrderSubscriptions.map((sub) => sub.unsubscribe());
      updateOrderSubscriptions.map((sub) => sub.unsubscribe());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Above Media | Orders</title>
      </Helmet>
      <OrdersSearch
        search={search}
        orderGroupOptions={orderGroupOptions}
        orderGroupId={orderGroupId}
        deliveryStatus={deliveryStatus}
        licenseStatus={licenseStatus}
        onSearch={onSearch}
      />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8">
        <div className="p-5 flex flex-row gap-4 items-center justify-center">
          <div className="grow gap-2 flex flex-row items-center font-semibold text-lg text-gray-900">
            Orders
          </div>
          <div className="flex-none flex flex-row gap-4 items-center">
            <Button rounded variant="defaultOutlined" onClick={onDownloadCsv}>
              <DownloadIcon className="stroke-gray" />
              Download CSV
            </Button>
            <Button
              disabled={selectedRows.size === 0}
              rounded
              variant="primary"
              className="flex flex-row gap-1"
              onClick={onBulkApprove}>
              <CheckIcon className="stroke-white" /> Approve
            </Button>
            <Button
              disabled={selectedRows.size === 0}
              rounded
              variant="dangerOutlined"
              className="flex flex-row gap-1"
              onClick={onBulkReject}>
              <XCircleIcon className="stroke-danger" /> Reject
            </Button>
          </div>
        </div>
        <div className="relative">
          {isLoading && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50"></div>
              <div className="border-t border-gray-100">
                <Skeleton />
              </div>
            </>
          )}

          {!isLoading && (
            <Table
              data={results || []}
              columns={columns}
              multipleSelection={true}
              rowId="_id"
              selectedRows={selectedRows}
              onSelectChange={(keys) => setSelectedRows(keys)}
              disabledRows={[]}
              pagination={{
                totalItems: meta?.total || 0,
                itemsPerPage: meta?.pageSize || 30,
                currentPage: parseInt(page),
                onPageChange: (page) => setSearchParams({ ...queryParams, page: page.toString() })
              }}
              noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
              noDataLabel="No orders were found!"
              noDataDescription="Your search did not match any order. Please try again later."
            />
          )}
        </div>
      </div>
    </>
  );
}
