import { graphql } from 'gql.tada';

export const addOrganization = graphql(`
  mutation addOrganization(
    $name: String!
    $orgId: String!
    $orderTypes: [DeliveryType]!
    $sourceCatalogs: [SourceCatalog]!
    $clients: [OrganizationClientInput]!
    $mezzaninePoDestinations: [String]!
    $billingPrefix: String
  ) {
    createOrganization(
      organization_input: {
        orgId: $orgId
        name: $name
        orderTypes: $orderTypes
        sourceCatalogs: $sourceCatalogs
        clients: $clients
        mezzaninePoDestinations: $mezzaninePoDestinations
        billingPrefix: $billingPrefix
      }
    ) {
      orgId
    }
  }
`);

export const updateOrganization = graphql(`
  mutation updateOrganization(
    $name: String!
    $orgId: String!
    $orderTypes: [DeliveryType]!
    $sourceCatalogs: [SourceCatalog]!
    $clients: [OrganizationClientInput]!
    $mezzaninePoDestinations: [String]!
    $billingPrefix: String
  ) {
    updateOrganization(
      organization_input: {
        orgId: $orgId
        name: $name
        orderTypes: $orderTypes
        sourceCatalogs: $sourceCatalogs
        clients: $clients
        mezzaninePoDestinations: $mezzaninePoDestinations
        billingPrefix: $billingPrefix
      }
      orgId: $orgId
    ) {
      orgId
    }
  }
`);

export const deleteOrganization = graphql(`
  mutation deleteOrganization($orgId: String!) {
    deleteOrganization(orgId: $orgId)
  }
`);
