import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Header = lazy(() => import('../../components/shared/Header'));
const TitleCardPage = lazy(() => import('../titleCard/TitleCardPage'));

export default function TitleCardsRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="" element={<TitleCardPage />} />
        <Route path="*" element={<Navigate to="/title-cards" replace />} />
      </Routes>
    </>
  );
}
