import { graphql } from 'gql.tada';

export const createOrder = graphql(`
  mutation createOrder(
    $externalId: String
    $brand: String
    $orderGroupId: String!
    $organization: String!
    $sourceCatalog: SourceCatalog!
    $titles: [OrderTitleInput]!
  ) {
    createOrder(
      orderInput: {
        externalId: $externalId
        orderGroupId: $orderGroupId
        organization: $organization
        sourceCatalog: $sourceCatalog
        titles: $titles
        brand: $brand
      }
    ) {
      _id
    }
  }
`);

export const createOrderGroup = graphql(`
  mutation createOrderGroup(
    $orgId: String!
    $title: String!
    $clientId: String!
    $clientText: String!
  ) {
    createOrderGroup(
      orderGroupInput: {
        orgId: $orgId
        title: $title
        clientId: $clientId
        clientText: $clientText
      }
    )
  }
`);

export const approveOrder = graphql(`
  mutation approveOrder($id: ID!) {
    approveOrder(_id: $id) {
      _id
      organization
      licenseStatus
      deliveryStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
        }
      }
    }
  }
`);

export const rejectOrder = graphql(`
  mutation rejectOrder($id: ID!) {
    rejectOrder(_id: $id) {
      _id
      organization
      licenseStatus
      deliveryStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
        }
      }
    }
  }
`);

export const restartOrderDelivery = graphql(`
  mutation restartOrderDelivery($id: ID!) {
    restartOrderDelivery(_id: $id)
  }
`);

export const cancelOrderDelivery = graphql(`
  mutation cancelOrderDelivery($id: ID!) {
    cancelOrderDelivery(_id: $id) {
      _id
      organization
      deliveryStatus
      licenseStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
        }
      }
    }
  }
`);

export const addOrderMessage = graphql(`
  mutation addOrderMessage($id: ID!, $message: String!) {
    addOrderMessage(_id: $id, message: $message) {
      _id
      action
      message
      timestamp
      user
      userFullName
      userOrganization
      userOrganizationName
    }
  }
`);

export const cancelOrderTitleDelivery = graphql(`
  mutation cancelOrderTitleDelivery($id: ID!, $deliveryId: ID!) {
    cancelOrderTitleDelivery(_id: $id, deliveryId: $deliveryId) {
      _id
      organization
      deliveryStatus
      licenseStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
        }
      }
    }
  }
`);

export const updateOrderTitleDelivery = graphql(`
  mutation updateOrderTitleDelivery(
    $id: ID!
    $deliveryId: ID!
    $payload: UpdateOrderTitleDeliveryInput
  ) {
    updateOrderTitleDelivery(_id: $id, deliveryId: $deliveryId, payload: $payload) {
      _id
    }
  }
`);

export const approveEncodePreview = graphql(`
  mutation approveEncodePreview($id: ID!, $deliveryId: ID!) {
    approveEncodePreview(_id: $id, deliveryId: $deliveryId) {
      _id
      organization
      deliveryStatus
      licenseStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
          history {
            user
            userFullName
            userOrganization
            userOrganizationName
            action
            message
            timestamp
          }
        }
      }
    }
  }
`);

export const updateOrder = graphql(`
  mutation updateOrder($id: ID!, $payload: UpdateOrderInput) {
    updateOrder(_id: $id, payload: $payload) {
      _id
      organization
      licenseStatus
      deliveryStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
        }
      }
    }
  }
`);

export const addOrderTitleDelivery = graphql(`
  mutation addOrderTitleDelivery(
    $id: ID!
    $titleCatalogId: ID!
    $payload: OrderTitleDeliveryInput
  ) {
    addOrderTitleDelivery(_id: $id, titleCatalogId: $titleCatalogId, payload: $payload) {
      _id
      organization
      deliveryStatus
      licenseStatus
      externalId
      brand
      titles {
        deliveries {
          deliveryId
          status
          audioLanguages
          subtitleLanguages
          deliveryFileName
        }
      }
    }
  }
`);
