import Modal from '../ui/Modal';
import Button from '../ui/Button';
import Switch from '../ui/Switch';
import { AssetConfiguration } from './AssetsView';
import { CatalogTitle, System } from '../../features/catalog/types';
import { OrderItemTitleTypeLabels } from '../../common/types';
import { useState } from 'react';

export interface AssetsViewConflictModalContextItem {
  title: CatalogTitle;
  system: System;
  previousValue: AssetConfiguration;
  newValue: AssetConfiguration;
}

export interface AssetsViewConflictModalContext {
  items: AssetsViewConflictModalContextItem[];
  assetConfigObj: { [key: string]: any };
  onResolve: (value: { [p: string]: any } | PromiseLike<{ [p: string]: any }>) => void;
  onReject: () => void;
}

const AssetConflictModal = ({ context }: { context?: AssetsViewConflictModalContext }) => {
  if (!context?.items) return null;

  const groupedItems: { [key: string]: AssetsViewConflictModalContextItem[] } = {};
  const initialValues: {
    [key: string]: { [key: string]: boolean };
  } = {};
  for (const item of context.items) {
    if (groupedItems[item.title._id]) groupedItems[item.title._id].push(item);
    else groupedItems[item.title._id] = [item];

    initialValues[item.title._id] = { ...initialValues[item.title._id], [item.system.value]: true };
  }

  const { items, assetConfigObj, onResolve, onReject } = context;

  const [conflictResolution, setConflictResolution] = useState<{
    [key: string]: { [key: string]: boolean };
  }>(initialValues);

  const handleApply = () => {
    for (const key in conflictResolution) {
      for (const system in conflictResolution[key]) {
        if (!conflictResolution[key][system]) {
          delete assetConfigObj[key]['assetSystems'][system];
          delete assetConfigObj[key]['filenames'][system];
        }
      }

      if (assetConfigObj[key] && Object.keys(assetConfigObj[key]['assetSystems'])?.length === 0) {
        delete assetConfigObj[key];
      }
    }

    onResolve(assetConfigObj);
  };
  return (
    <Modal
      visible={true}
      setVisible={onReject}
      position={'center'}
      title={`${items.length} Conflict${items.length > 1 ? 's' : ''} Found`}
      className={'min-w-[650px]'}>
      <div className={'py-4 px-6'}>
        <div className={'text-gray-600 text-sm font-normal '}>
          Please choose if you want to keep or overwrite.
        </div>
        <div className={'flex justify-end text-gray-700'}>Overwrite</div>
        {Object.keys(groupedItems).map((key, idx) => (
          <ConflictTitle
            key={'conflict-' + idx}
            data={groupedItems[key]}
            conflictGroupResolution={conflictResolution[key] || {}}
            setConflictGroupResolution={(value) =>
              setConflictResolution({ ...conflictResolution, [key]: value })
            }
          />
        ))}

        <div className={'mt-8 flex justify-end gap-2'}>
          <Button variant={'defaultOutlined'} rounded onClick={onReject}>
            Cancel
          </Button>
          <Button variant={'primary'} rounded onClick={handleApply}>
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ConflictTitle = ({
  data,
  conflictGroupResolution,
  setConflictGroupResolution
}: {
  data: AssetsViewConflictModalContextItem[];
  conflictGroupResolution: { [key: string]: boolean };
  setConflictGroupResolution: (arg: { [key: string]: boolean }) => void;
}) => {
  const title = data[0].title;
  return (
    <div>
      <div className={'text-primary-600 text-sm mb-1 mt-2'}>
        <span className={'font-medium'}>{OrderItemTitleTypeLabels[title.type]}: </span>
        <span className={'font-bold'}>{title.name}</span>
      </div>
      <div className={'flex flex-col gap-1'}>
        {data.map((item, idx) => (
          <ConflictSystem
            key={'conflict-item-' + idx}
            data={item}
            conflictItemResolution={conflictGroupResolution[item.system.value]}
            setConflictItemResolution={(value) =>
              setConflictGroupResolution({ ...conflictGroupResolution, [item.system.value]: value })
            }
          />
        ))}
      </div>
    </div>
  );
};

const ConflictSystem = ({
  data,
  conflictItemResolution,
  setConflictItemResolution
}: {
  data: AssetsViewConflictModalContextItem;
  conflictItemResolution?: boolean;
  setConflictItemResolution: (arg: boolean) => void;
}) => {
  const system = data.system;
  return (
    <div className={'flex gap-2 justify-between items-center'}>
      <div className={'text-gray-600 hover:underline capitalize'}>
        {system.poDestination} - {system.label} - ({system.ifeFormat})
      </div>
      <div>
        <Switch checked={conflictItemResolution} onCheckedChange={setConflictItemResolution} />
      </div>
    </div>
  );
};

export default AssetConflictModal;
