import { CatalogTitle } from '../features/catalog/types';
import { EidrRecord } from '../features/eidr/types';
import { OrderTitle } from '../features/orders/types';

export const EMPTY_CELL_TEXT = '—';

export const getOptions = (labels: { [key: string]: string }) =>
  Object.entries(labels).map(([key, label]) => ({ value: key, label: label }));

// reverse key-value pair lookup
export const getValue = (input: Record<string, string>) => {
  const entries = Object.entries(input).map(([key, value]) => [value, key]);
  return Object.fromEntries(entries);
};

export const eidrRecordMpmNumber = (eidrRecord: EidrRecord | undefined) =>
  (eidrRecord &&
    eidrRecord?.AlternateID.find((a) => a?._domain?.toLowerCase() === 'warnerbros.com/mpm')
      ?.value) ||
  '';

export const downloadFile = (content: string, filename: string) => {
  const anchor = document.createElement('a');
  anchor.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content));
  anchor.setAttribute('download', filename);
  anchor.click();
  anchor.remove();
};

export const diffValues = (updated: any, reference: any) => {
  const diff = { ...updated };
  Object.keys(diff).map((key: string) => {
    if (reference[key] && diff[key].toString() === reference[key].toString()) delete diff[key];
  });
  return diff;
};

export const orderTitleToCatalogTitle = (orderTitle: OrderTitle): CatalogTitle => ({
  _id: orderTitle.titleCatalogId,
  name: orderTitle.name,
  releaseDate: '',
  type: orderTitle.titleType === 'EPISODE' ? 'EPISODE' : 'MOVIE',
  mpmNumber: '',
  eidrId: orderTitle.titleEidrId,
  sourceCatalog: null,
  edits: [],
  seasonNumber: orderTitle.seasonNumber ? parseInt(orderTitle.seasonNumber) : null,
  seasonNumbers: orderTitle.seasonNumber ? [parseInt(orderTitle.seasonNumber)] : null,
  episodeNumber: orderTitle.episodeNumber ? parseInt(orderTitle.episodeNumber) : null,
  series: {
    _id: '',
    eidrId: '',
    name: orderTitle.seriesName || ''
  }
});

/**
 * Converts a file size in bytes to a human-readable string format.
 *
 * @param {number} size - The file size in bytes.
 * @returns {string} The human-readable file size string.
 */

export const formatFileSize = (size: number): string => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'KiB', 'MiB', 'GiB', 'TiB'][i]}`;
};

/**
 * Format duration in seconds to HH:MM:SS
 *
 * @param {number} duration - The duration in seconds.
 * @returns {string} The duration in HH:MM:SS.
 */
export const formatDuration = (duration: string | number): string =>
  new Date((typeof duration === 'string' ? parseFloat(duration) : duration) * 1000)
    .toISOString()
    .slice(11, 19);
