import { generateClient } from 'aws-amplify/api';
import { type GraphQLResult } from '@aws-amplify/api';
import * as queries from '../../graphql/queries/organizations';
import * as mutations from '../../graphql/mutations/organizations';
import {
  GetOrganizationResponse,
  GetOrganizationsResponse,
  Organization,
  OrganizationClient
} from './types';
import { pickBy } from 'lodash';
import { Meta } from '../../common/types';
import { toast } from 'react-hot-toast';
import { print } from 'graphql';

const client = generateClient();

export const getOrganizations = async (
  variables: {
    page?: number;
    pageSize?: number;
  },
  cb?: (result: { results: Organization[]; meta: Meta | null } | undefined) => void
): Promise<void> => {
  const params = pickBy(variables, (v) => v !== undefined);
  const result = (await client.graphql({
    query: print(queries.getOrganizations),
    variables: params
  })) as GraphQLResult<GetOrganizationsResponse>;
  cb && cb(result.data?.listOrganizations);
};

export const deleteOrganization = async (orgId: string, cb?: () => void): Promise<void> => {
  try {
    await client.graphql({
      query: print(mutations.deleteOrganization),
      variables: { orgId }
    });
    cb && cb();
  } catch (error) {
    console.error(error);
    toast.error(
      'You cannot delete the organization, please check the logs or call the administrator'
    );
  }
};

export const addOrganization = async (
  params: {
    name: string;
    orgId: string;
    orderTypes: string[];
    sourceCatalogs: string[];
    clients: OrganizationClient[];
    mezzaninePoDestinations: string[];
  },
  cb?: () => void
): Promise<void> => {
  try {
    await client.graphql({
      query: print(mutations.addOrganization),
      variables: params
    });
    cb && cb();
  } catch (error) {
    console.error(error);
    toast.error('You cannot add the organization, please check the logs or call the administrator');
  }
};

export const updateOrganization = async (
  params: {
    name: string;
    orgId: string;
    orderTypes: string[];
    sourceCatalogs: string[];
    clients: OrganizationClient[];
    mezzaninePoDestinations: string[];
  },
  cb?: () => void
): Promise<void> => {
  try {
    await client.graphql({
      query: print(mutations.updateOrganization),
      variables: params
    });
    cb && cb();
  } catch (error) {
    console.error(error);
    toast.error(
      'You cannot update the organization, please check the logs or call the administrator'
    );
  }
};

export const getOrganization = async (
  orgId: string,
  cb?: (result: Organization | null) => void
): Promise<void> => {
  const result = (await client.graphql({
    query: print(queries.getOrganization),
    variables: {
      orgId
    }
  })) as GraphQLResult<GetOrganizationResponse>;
  cb && cb(result.data?.getOrganizationByOrgId || null);
};
