import React, { useEffect, useState } from 'react';
import Button from '../ui/Button';
import PlusIcon from '../../assets/icons/plus.svg?react';
import Modal from '../ui/Modal';
import FormItem from '../ui/FormItem';
import Input from '../ui/Input';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import Select from '../ui/Select';
import { Organization, OrganizationClient } from '../../features/organizations/types';
import { CreateOrderGroup } from '../../features/orders/types';
import { addOrderGroup } from '../../features/orders/ordersApi';

export interface CycleFormProps {
  client: string;
  cycle: string;
  month?: string;
  year?: string;
}

const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 4 }, (_, i) => {
  const year = currentYear - 1 + i;
  return { value: year.toString(), label: year.toString() };
});

export default function AddCycleFormModal({
  onCycleCreate,
  selectedOrg,
  modalOpen,
  setModalOpen
}: {
  onCycleCreate: (newOrderGroupId: string) => void;
  selectedOrg: Organization | undefined;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}) {
  const { register, handleSubmit, formState, reset, control, watch } = useForm<CycleFormProps>({
    defaultValues: {
      client: '',
      cycle: ''
    }
  });
  const selectedClient = watch('client');
  const [showYearMonthSelect, setShowYearMonthSelect] = useState(false);

  const clientsOptions = selectedOrg?.clients.map((client: OrganizationClient) => ({
    value: client.clientId,
    label: client.name
  }));

  useEffect(() => {
    const client = selectedOrg?.clients.find((client) => client.clientId === selectedClient);
    setShowYearMonthSelect(client?.orderGroupYearMonthRequired || false);
  }, [selectedClient, selectedOrg]);

  const onFormSubmit: SubmitHandler<CycleFormProps> = (values) => {
    const client = selectedOrg?.clients.find((client) => client.clientId === values.client);

    if (client && selectedOrg) {
      const newCycle: CreateOrderGroup = {
        orgId: selectedOrg?.orgId,
        title: showYearMonthSelect ? `${values.year}/${values.month}` : values.cycle,
        clientId: values.client,
        clientText: client.name
      };

      addOrderGroup(newCycle, (result) => {
        onCycleCreate(result?.createOrderGroup || '');
      });
    }
    setModalOpen(false);
  };

  useEffect(() => {
    reset({
      client: '',
      cycle: '',
      month: '',
      year: ''
    });
  }, []);

  return (
    <Modal
      title={
        <>
          Create Order Group
          <div className="text-gray text-sm font-light">
            This entity will help grouping your orders. Traditionally this is a cycle.
          </div>
        </>
      }
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={() => setModalOpen(false)}>
      <form className="divide-y divide-gray-100">
        <FormItem label="Client*" layout="vertical">
          <Controller
            name="client"
            rules={{
              required: {
                value: true,
                message: 'Client is required!'
              }
            }}
            control={control}
            render={({ field }) => (
              <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row gap-4">
                  <Select
                    className="w-full"
                    options={clientsOptions}
                    placeholder="Choose the client"
                    onChange={(option: any) => field.onChange(option.value)}
                    defaultValue={clientsOptions?.filter((option) => field.value === option.value)}
                    value={clientsOptions?.filter((option) => field.value === option.value)}
                  />
                </div>
                {formState.errors.client && (
                  <div className="text-danger-500 mt-1.5 text-sm">
                    {(formState.errors.client as FieldError).message}
                  </div>
                )}
              </div>
            )}
          />
        </FormItem>
        {showYearMonthSelect ? (
          <div className="flex flex-row">
            <FormItem label="Year*" layout="vertical" className="w-1/2">
              <Controller
                name="year"
                rules={{
                  required: {
                    value: true,
                    message: 'Year is required!'
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Select
                    className="w-full"
                    options={years}
                    placeholder="Select year"
                    onChange={(option: any) => field.onChange(option.value)}
                    value={years.filter((option) => field.value === option.value)}
                  />
                )}
              />
            </FormItem>
            <FormItem label="Month*" layout="vertical" className="w-1/2">
              <Controller
                name="month"
                rules={{
                  required: {
                    value: true,
                    message: 'Month is required!'
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Select
                    className="w-full"
                    options={months}
                    placeholder="Select month"
                    onChange={(option: any) => field.onChange(option.value)}
                    value={months.filter((option) => field.value === option.value)}
                  />
                )}
              />
            </FormItem>
          </div>
        ) : (
          <FormItem label="Group Title*" layout="vertical">
            <Input
              rounded
              className="grow"
              placeholder="Enter cycle month and year"
              error={formState.errors.cycle?.message}
              {...register('cycle', {
                required: { value: true, message: 'Group title is required!' }
              })}
            />
          </FormItem>
        )}
      </form>
      <div className="flex flex-row-reverse p-5 gap-2">
        <Button
          variant="primary"
          rounded
          type="submit"
          onClick={() => handleSubmit(onFormSubmit)()}
          disabled={!formState.isValid}>
          <PlusIcon className="w-5 h-5 stroke-white" /> Add
        </Button>
        <Button variant="defaultOutlined" rounded onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
